/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash-es';

export const getToken = async () => {
  try {
    const response = (await Auth.currentSession()).getIdToken().getJwtToken();

    return response;
  } catch (error) {
    return error;
  }
};

export const userExist = async (email: string, language: string) => {
  try {
    return await Auth.signIn(email.toLowerCase(), '');
  } catch (error) {
    const code = (error as { code: number | string }).code;
    if (code === 'UserNotConfirmedException') {
      return resendConfirmation(email, language);
    }
  }
};

// SIGN IN NORMAL
export const signIn = async (email: string, password: string) => {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    return error;
  }
};

// SIGN UP NORMAL
export const signUp = async (email: string, password: string, language: string) => {
  try {
    return await Auth.signUp({ username: email, password, clientMetadata: { lang: language } });
  } catch (error) {
    return error;
  }
};

// SIGN OUT NORMAL
export const signOut = async () => {
  try {
    const sO = await Auth.signOut();
    localStorage.clear();
    return sO;
  } catch (error) {
    return error;
  }
};

// VERIFY CODE for NORMAL SIGN UP
export const confirmSignUp = async (email: string, code: string) => {
  try {
    return await Auth.confirmSignUp(email, code);
  } catch (error) {
    return error;
  }
};

export const resendConfirmation = async (email: string, language: string) => {
  try {
    return await Auth.resendSignUp(email, { lang: language });
  } catch (error) {
    return error;
  }
};

// USER [SPEC]
export const getCurrentUser = async () => {
  try {
    const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return authUser;
  } catch (error) {
    return error;
  }
};

export const getUserAttr = () => {
  return getCurrentUser()
    .then((user) => {
      if (isEmpty(user)) return {};
      const { attributes } = user;
      return attributes;
    })
    .catch((error) => error);
};

export const updateUserAttr = async (attributes: { [key: string]: string | boolean | number }) => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    const res = await Auth.updateUserAttributes(authUser, attributes);
    return res;
  } catch (error) {
    return error;
  }
};

export const changeUserPassword = async (oldPassword: string, newPassword: string) => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    const res = await Auth.changePassword(authUser, oldPassword, newPassword);
    return res;
  } catch (error) {
    return error;
  }
};

export const sendForgotPasswordCode = async (email: string, language: string) => {
  try {
    return await Auth.forgotPassword(email, { lang: language });
  } catch (error) {
    return error;
  }
};

export const changePasswordForgot = async (email: string, code: string, newPassword: string) => {
  try {
    return await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    return error;
  }
};

// Change account email
export const changeAttr = async (
  val: string | number | boolean,
  language: string,
  type = 'email'
) => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(authUser, { [type]: val }, { lang: language });
  } catch (error) {
    return error;
  }
};

// Change account email - resend verification code
export const resendVerifyAttr = async (type = 'email', language: string) => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    return await Auth.verifyUserAttribute(authUser, type, { lang: language });
  } catch (error) {
    return error;
  }
};

export const verifyAttr = async (code: string, type = 'email') => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    return await Auth.verifyUserAttributeSubmit(authUser, type, code);
  } catch (error) {
    return error;
  }
};
