/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { DataReports } from 'components/csvReports/csvReports.types';
import {
  DefaultResponse,
  ApiResponse,
  GetGeneralUserDetailsResponse,
  PostSaveUserDetails,
  PostSaveUserDetailsResponse,
  ApiParams,
  DisplayedTiles,
  RegistrationData,
  RegistrationResponse,
  SaveBillingDetailsData,
  CreateSessionPaymentData,
  CreateSessionPaymentResponse,
  UpdatePaymentDetailsParams,
  RefundPaymentData,
  GetBillingDetailsResponse,
  AddNewHubPayload,
  AddNewHubResponse,
  GetUserHubsAndDevicesResponse,
  AddNewDevicePayload,
  AddNewDeviceResponse,
  EditHubPayload,
  EditHubDeviceResponse,
  EditDevicePayload,
  EditLibbiComponentsNicknamePayload,
  EditLibbiComponentsNicknameResponse,
  GetTechnicalFileParams,
  GetTechnicalFileResponse,
  GetVehicleResponse,
  UserVehicleReq,
  VehicleParam,
  UserVehicleResponse,
  getVehicleManufacturersResponse,
  GetLocationApiResponse,
  GetEnergyProviderResponse,
  GetPriceGraphApiParams,
  GetPriceGraphApiResponse,
  GetDualTariffApiParams,
  DualEconomyPrice,
  WeatherForecastApiResponse,
  DualTariffApiResponse,
  SaveLocationApiResponse,
  SaveLocationPayload,
  UserPreference,
  SetOptInDecision,
  Marketing,
  GetSchedulesResponse,
  SaveSchedulesData,
  SaveSchedulesResponse,
  SetIsActiveScheduleResponse,
  GetSharingInvitationsParam,
  GetSharingInvitationsResponse,
  SharingInvitationData,
  ResendInvitationData,
  ResendInvitationResponse,
  UpdateInvitationResponse,
  CheckApiKeyData,
  CheckApiKeyParams,
  GenerateApiKeyParams,
  DeleteDeviceParams,
  DeleteHubParams,
  DeleteUserAccountData,
  CheckApiKeyResponse,
  GetDeviceStatusParams,
  GetDeviceStatusResponse,
  OcppSettingsPayload,
  OcppConfigResponse,
  GetAccessGroupsResponse,
  GetAccessGroupsParams,
  EditPinData,
  DeletePinData,
  PostAccessGroupsResponse,
  SavePinData,
  PostAccessGroupData,
  PostAddDeviceToAccessGroupData,
  DeleteAccessGroupData,
  ApiTariffResponse,
  ApiChargeFromGridResponse,
  removeDeviceFromAccessGroupData,
  UpdateDevicesStatusResponse,
  AddPinsData,
  ValidatePinData,
  ValidatePinResponse,
  MasterLibbiData,
  GenerateOcppTagResponse
} from 'types';
import {
  GetChargingSessionsResponse,
  ToggleBusinessUsageData,
  ToggleBusinessUsageDataResponse,
  GetFriendlyNamesParams
} from 'types/chargeLogs';

import { ENDPOINTS } from './endpoints';
import { request } from './requests';

// USER REGISTRATION
export const registration = (data: RegistrationData): Promise<RegistrationResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.registration, data });
export const editUserMail = (data: RegistrationData): Promise<RegistrationResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.editUserMail, data });
export const sendRegistrationCompleteEmail = (): Promise<DefaultResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.sendRegistrationMail });

// PERSONAL DETAILS
export const generalUserDetails = (): Promise<GetGeneralUserDetailsResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.userDetails });
export const savePersonalDetails = (
  data: PostSaveUserDetails,
  params: ApiParams
): Promise<PostSaveUserDetailsResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.saveUserDetails, data, params });
export const updateUserTiles = (data: DisplayedTiles): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.userTiles, data });

// PREFERENCES
export const setOptInDecision = (data: SetOptInDecision): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.setOptInDecision, data });
export const setUserPreferences = (data: UserPreference): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.setUserPreferences, data });
export const setMarketingPreferences = (data: Marketing): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.setMarketingPreferences, data });

// VEHICLE
export const getVehicleManufacturers = (): Promise<getVehicleManufacturersResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getVehicleManufacturers });
export const getAllVehicleData = (): Promise<GetVehicleResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getAllVehicleData });
export const getUserVehicles = (): Promise<GetVehicleResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getUserVehicles });
export const addVehicle = (data: UserVehicleReq): Promise<UserVehicleResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.userVehicle, data });
export const deleteVehicle = (params: VehicleParam): Promise<UserVehicleResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.userVehicle, params });
export const editVehicle = (data: UserVehicleReq): Promise<UserVehicleResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.userVehicle, data });

// ENERGY SETUP // LOCATION
export const getUserEnergySetup = (): Promise<GetLocationApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getLocations });
export const getEnergyProviders = (): Promise<GetEnergyProviderResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getEnergyProviders });
export const getBestPriceIntervalsGraph = (
  params: GetPriceGraphApiParams
): Promise<GetPriceGraphApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getBestPriceIntervalsGraph, params });
export const getDualTariffEnergyPrices = (
  params: GetDualTariffApiParams
): Promise<DualTariffApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getDualTariffEnergyPrices, params });
export const saveEnergySetup = (
  data: SaveLocationPayload,
  params: ApiParams
): Promise<SaveLocationApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.userLocation, data, params });
export const saveNewEnergySetup = (data: SaveLocationPayload): Promise<SaveLocationApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.userLocation, data });
export const deleteEnergySetup = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.userLocation, params });
export const saveDualTariffEnergyPrices = (
  data: DualEconomyPrice[],
  params: ApiParams
): Promise<DualTariffApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.saveDualTariffEnergyPrices, data, params });
export const getWeatherForecast = (params: ApiParams): Promise<WeatherForecastApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.forecast, params });

// PRODUCTS
export const updateDevices = (params: ApiParams): Promise<UpdateDevicesStatusResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.updateDevices, params });
export const getUserHubsAndDevices = (params: ApiParams): Promise<GetUserHubsAndDevicesResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getUserHubsAndDevices, params });
export const getDeletedHubsAndDevices = (
  params: ApiParams
): Promise<GetUserHubsAndDevicesResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getDeletedHubsAndDevices, params });
export const getDeviceStatus = (params: GetDeviceStatusParams): Promise<GetDeviceStatusResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.deviceStatus, params });
export const addNewHub = (data: AddNewHubPayload, params: ApiParams): Promise<AddNewHubResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.addNewHub, data, params });
export const addNewDevice = (
  data: AddNewDevicePayload[],
  params: ApiParams
): Promise<AddNewDeviceResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.addNewDevice, data, params });
export const editHub = (data: EditHubPayload, params: ApiParams): Promise<EditHubDeviceResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.editHub, data, params });
export const editDevice = (
  data: EditDevicePayload,
  params: ApiParams
): Promise<EditHubDeviceResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.editDevice, data, params });
export const getDevicesNickname = (): Promise<ApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.updateDevicesNickname });
export const editLibbiComponentsNickname = (
  data: EditLibbiComponentsNicknamePayload,
  params: ApiParams
): Promise<EditLibbiComponentsNicknameResponse> =>
  request({ method: 'patch', endpoint: ENDPOINTS.updateLibbiComponentsNickname, data, params });
export const getTechnicalFileData = (
  params: GetTechnicalFileParams
): Promise<GetTechnicalFileResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getTechnicalFileData, params });
export const getOcppSettings = (params: ApiParams): Promise<OcppConfigResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getOcppSettings, params });
export const saveOcppSettings = (
  data: OcppSettingsPayload,
  params: ApiParams
): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.setOcppSettings, data, params });
export const generateNewOcppTag = (params: ApiParams): Promise<GenerateOcppTagResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.generateNewOcppTag, params });

// ACCOUNT SETTINGS
export const checkApiKey = (
  data: CheckApiKeyData,
  params: CheckApiKeyParams
): Promise<CheckApiKeyResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.checkAPIKey, data, params });
export const generateApiKey = (params: GenerateApiKeyParams): Promise<ApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.generateAPIKey, params });
export const deleteDevice = (params: DeleteDeviceParams): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.deleteDevice, params });
export const deleteHub = (params: DeleteHubParams): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.deleteHub, params });
export const deleteUserAccount = (data: DeleteUserAccountData): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.deleteAccount, data });

// ACCOUNT SHARING
export const getAccountSharingInvitations = (
  params: GetSharingInvitationsParam
): Promise<GetSharingInvitationsResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getInvitations, params });
export const sendAccountSharingInvitation = (data: SharingInvitationData): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.sendInvitation, data });
export const resendInvitationEmail = (
  data: ResendInvitationData
): Promise<ResendInvitationResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.resendInvitationEmail, data });
export const acceptAccountSharingInvitation = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.acceptInvitation, params });
export const rejectAccountSharingInvitation = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.rejectInvitation, params });
export const updateInvitation = (data: SharingInvitationData): Promise<UpdateInvitationResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.updateInvitation, data });
export const deleteAccountSharingInvitation = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.deleteInvitation, params });
export const deleteInvitationByGuest = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.deleteInvitationByGuest, params });

// GRAPHS
export const retrieveSpecificDataForTotalGraph = (data: any, params: any) =>
  request({ method: 'post', endpoint: ENDPOINTS.retrieveSpecificDataForTotalGraph, data, params });
export const retrieveSpecificDataForDeviceGraph = (data: any, params: any) =>
  request({ method: 'post', endpoint: ENDPOINTS.retrieveSpecificDataForDeviceGraph, data, params });
export const retrieveGraphData = (data: any, params: any) =>
  request({ method: 'post', endpoint: ENDPOINTS.retrieveGraphData, data, params });
export const retrieveGraphDataHourly = (data: any, params: any) =>
  request({ method: 'post', endpoint: ENDPOINTS.retrieveGraphDataHourly, data, params });

// CHARGE SCHEDULE
export const getAllSchedules = (params: ApiParams): Promise<GetSchedulesResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getAllSchedules, params });
export const saveDeviceSchedules = (
  data: SaveSchedulesData,
  params: ApiParams
): Promise<SaveSchedulesResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.saveSchedules, data, params });
export const setIsActiveSchedule = (
  data: SaveSchedulesData,
  params: ApiParams
): Promise<SetIsActiveScheduleResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.setIsActiveSchedule, data, params });
export const getLibbiMode = (params: ApiParams): Promise<ApiChargeFromGridResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.libbiMode, params });
export const toggleLibbiMode = (params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.libbiMode, params });
export const checkDisplayScedules = (params: ApiParams): Promise<ApiTariffResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.displaySchedules, params });
export const toggleLibbiSyncMode = (
  data: MasterLibbiData,
  params: ApiParams
): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.libbiSyncMode, data, params });

// PAYMENT
export const getBillingDetails = (): Promise<GetBillingDetailsResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.billingDetails });
export const saveBillingDetails = (data: SaveBillingDetailsData): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.saveBillingDetails, data });
export const createSessionPayment = (
  data: CreateSessionPaymentData
): Promise<CreateSessionPaymentResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.createSession, data });
export const updatePaymentDetails = (params: UpdatePaymentDetailsParams): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.updatePaymentDetails, params });
export const updateDevicesAsEW = (params: UpdatePaymentDetailsParams): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.updateWarrantyAndSendConfirmationMail, params });
export const refundPayment = (data: RefundPaymentData): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.refund, data });

// CHARGE LOGS
export const getChargingSessions = (params: ApiParams): Promise<GetChargingSessionsResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getChargingSessions, params });
export const downloadChargingSessions = (params: ApiParams): Promise<string> =>
  request({
    method: 'get',
    endpoint: ENDPOINTS.downloadChargingSessions,
    params,
    responseType: 'blob'
  });
export const toggleBusinessUsage = (
  data: ToggleBusinessUsageData,
  params: ApiParams
): Promise<ToggleBusinessUsageDataResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.toggleBusinessUsage, data, params });

// ACCESS GROUPS
export const getAccessGroups = (params: GetAccessGroupsParams): Promise<GetAccessGroupsResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.getAccessGroups, params });
export const postAccessGroups = (
  data: PostAccessGroupData,
  params: ApiParams
): Promise<PostAccessGroupsResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.postAccessGrouops, data, params });
export const postAddDeviceToAccessGroup = (
  data: PostAddDeviceToAccessGroupData,
  params: ApiParams
): Promise<PostAccessGroupsResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.addDeviceToAccessGroup, data, params });
export const deleteAccessGroup = (
  data: DeleteAccessGroupData,
  params: ApiParams
): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.deleteAccessGroup, data, params });
export const deleteDeviceFromAccessGroup = (
  data: removeDeviceFromAccessGroupData,
  params: ApiParams
): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.removeDeviceFromAccessGroup, data, params });

// PIN
export const editPin = (data: EditPinData, params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'put', endpoint: ENDPOINTS.pinEndpoint, data, params });
export const deletePin = (data: DeletePinData, params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'delete', endpoint: ENDPOINTS.pinEndpoint, data, params });
export const savePin = (data: SavePinData, params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.pinEndpoint, data, params });
export const getFriendlyNames = (params: GetFriendlyNamesParams): Promise<ApiResponse> =>
  request({ method: 'get', endpoint: ENDPOINTS.devicePinFriendlyNames, params });
export const addPins = (data: AddPinsData, params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.addPins, data, params });
export const validatePin = (
  data: ValidatePinData,
  params: ApiParams
): Promise<ValidatePinResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.validatePin, data, params });

// REPORTS

export const generateCSVReport = (data: DataReports, params: ApiParams): Promise<ApiResponse> =>
  request({ method: 'post', endpoint: ENDPOINTS.generateCSVReport, data, params });
