/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import FormError from 'components/elements/formError';
import { Field, FieldMetaProps, FieldInputProps } from 'formik';
import { isEmpty } from 'lodash';
import { FC, useRef } from 'react';

import Icon from '../icon';
import InfoPopUp from '../infoPopup';

interface ITextBox {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name: string;
  placeholder?: string;
  value?: string | number;
  isDisabled?: boolean;
  autoComplete?: string;
  type?: string;
  label?: string;
  infoPopUpText?: string;
  infoPopUpTitle?: string;
  isControlled?: boolean;
  iconName?: string;
  hidden?: boolean;
  className?: string;
  autoFocus?: boolean;
  hasLabelTooltip?: boolean;
  labelTooltipData?: {
    title?: string;
    content?: React.ReactElement<HTMLDivElement> | string;
    position?: 'top' | 'bottom' | 'left' | 'right';
  };
  // eslint-disable-next-line
  [x: string]: any;
}

const Textbox: FC<ITextBox> = ({
  onChange,
  id,
  name,
  placeholder,
  value,
  isDisabled,
  autoComplete,
  type,
  label,
  infoPopUpText = '',
  infoPopUpTitle = '',
  isControlled,
  iconName,
  hidden,
  className,
  autoFocus,
  hasLabelTooltip = false,
  labelTooltipData,
  ...inputProps
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: FieldInputProps<never>
  ) => {
    const { onChange: formikOnChange } = field;

    formikOnChange(event);

    if (typeof onChange === 'function') {
      onChange(event);
    }

    return;
  };
  const hasIcon = () => iconName || infoPopUpText || infoPopUpTitle;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickDiv = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <Field name={name}>
      {({ field, meta }: { field: FieldInputProps<never>; meta: FieldMetaProps<never> }) => (
        <div
          className={`form-textbox ${className || ''} ${isDisabled ? 'disabled' : ''} ${hidden ? 'hidden' : ''}
          ${meta.touched && meta.error ? 'error' : ''}`}
          data-testid="text-box"
        >
          {label && (
            <label htmlFor={id}>
              {label}
              {hasLabelTooltip ? (
                <span className="label-tooltip">
                  <InfoPopUp
                    title={labelTooltipData?.title}
                    position={labelTooltipData?.position || 'top'}
                    content={labelTooltipData?.content}
                  >
                    <div className="modal">
                      <Icon name="warranty-tooltip" />
                    </div>
                  </InfoPopUp>
                </span>
              ) : (
                <></>
              )}
            </label>
          )}
          <div
            className={`form-textbox__input ${hasIcon() ? 'has-info' : ''}`}
            onClick={handleClickDiv}
            data-testid="text-box-input-wrapper"
          >
            <input
              id={id}
              type={type || 'text'}
              {...field}
              onChange={(e) => handleChange(e, field)}
              value={isControlled ? value || '' : field.value}
              placeholder={placeholder}
              disabled={isDisabled || (isControlled && !onChange)}
              autoComplete={autoComplete || ''}
              autoFocus={autoFocus}
              ref={inputRef}
              {...inputProps}
              data-testid="text-box-input"
            />

            {isEmpty(infoPopUpText) && isEmpty(infoPopUpTitle) && iconName && (
              <Icon name={iconName || 'tooltip'} />
            )}

            {!isEmpty(infoPopUpText) && !isEmpty(infoPopUpTitle) && iconName && (
              <InfoPopUp title={infoPopUpTitle} text={infoPopUpText} position="top">
                <Icon name={iconName || 'tooltip'} />
              </InfoPopUp>
            )}
          </div>
          <FormError meta={meta} className={className} />
        </div>
      )}
    </Field>
  );
};

export default Textbox;
