/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Period } from 'constants/enums';
import { isEqual } from 'lodash-es';
import dateCreator from 'utils/dateCreator';
import dayjs from 'utils/dayjs';

import { FL, DATE_FORMATS, SHORT_MONTHS } from '../sharedEnums';
import { addZero } from '../sharedHelpers';

export const dateFormated = (val: string, type: string | null) => {
  if (!val) return '';

  switch (type) {
    case FL.APP:
      return `${val}`.indexOf('-') > -1
        ? `${val}`.replaceAll('-', '/')
        : `${val}`.replaceAll('dd', 'DD');
    case FL.SERVER:
      return `${val}`.replaceAll('/', '-');
    case FL.DATABASE:
      return `${val}`.replaceAll('DD', 'd').replaceAll('MM', 'M').replaceAll('YYYY', 'yyyy');
    default:
      return val === 'M/d/YYYY' || val === 'M/d/yyyy'
        ? DATE_FORMATS.MM_DD_YYYY
        : DATE_FORMATS.DD_MM_YYYY;
  }
};

export const processDaysFromStringToArray = (str: string) =>
  String(str)
    .split(',')
    .map((item) => Number(item))
    .sort();

export const processDaysFromArrayToString = (arr: number[] | string[] | null) => {
  return arr ? arr.sort().join(',') : '';
};

export interface IMonthTranslation {
  [month: string]: string;
}

export const getShortMonth = (date: Date, monthTranslation: IMonthTranslation | null) => {
  return monthTranslation
    ? monthTranslation?.[SHORT_MONTHS[date.getUTCMonth()]]
    : SHORT_MONTHS[date.getUTCMonth()];
};

export const getFormattedDate = (date: Date, monthTranslation: IMonthTranslation) => {
  return `${addZero(date.getUTCDate())} ${getShortMonth(date, monthTranslation)}`;
};

export const getTime = (date: Date) => `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;

export const isSameDay = (date1: Date, date2: Date) => date1.getUTCDate() === date2.getUTCDate();

export const getDisplayDateFromActiveDate = (period: string) => {
  const day = new Date();

  switch (period) {
    case Period.TODAY:
      return dayjs(day).format('YYYY-MM-DD');
    case Period.TOMORROW:
      day.setDate(day.getUTCDate() + 1);
      return dayjs(day).format('YYYY-MM-DD');
    case Period.YESTERDAY:
      day.setDate(day.getUTCDate() - 1);
      return dayjs(day).format('YYYY-MM-DD');
    default:
      return dayjs(day).format('YYYY-MM-DD');
  }
};

interface IDayLabels {
  [month: string]: string;
}

export const getDaysLabel = (str: string | null, dayLabels: IDayLabels) => {
  if (!str) return null;

  const days = processDaysFromStringToArray(str);

  if (isEqual(days, [1, 2, 3, 4, 5])) return dayLabels?.weekdays;
  if (isEqual(days, [0, 6])) return dayLabels?.weekend;
  if (isEqual(days, [0, 1, 2, 3, 4, 5, 6])) return dayLabels?.everyDay;

  const dayLabel: string[] = [];

  days.forEach((d) => {
    dayLabel.push(dayLabels[d]);
  });

  return dayLabel.length > 1 ? dayLabel.join(' ') : dayLabel[0];
};

export const reverseHandleTime = (time: number | null) => {
  if (!time) return { hours: 0, minutes: 0 };
  if (time === 1440) return { hours: 0, minutes: 0 };

  const stringTimeSplit = String((time / 60).toFixed(8)).split('.');
  const dotlength = stringTimeSplit[1]?.length;
  const pow = Math.pow(10, dotlength);
  const minutes = Math.round((parseInt(stringTimeSplit[1]) / pow) * 60);
  const hours = parseInt(stringTimeSplit[0]);

  return { hours, minutes };
};

export const getDateFromActiveDate = (period: string | string[]) => {
  const todayStart = dateCreator.now.utc();
  const todayEnd = dateCreator.now.utc();
  const yesterdayStart = dateCreator.yesterday.utc();
  const yesterdayEnd = dateCreator.yesterday.utc();
  const startOfThisWeek = dateCreator.startOfThisWeek.utc();
  const endOfThisWeek = dateCreator.endOfThisWeek.utc();
  const startOfLastWeek = dateCreator.startOfLastWeek.utc();
  const endOfLastWeek = dateCreator.endOfLastWeek.utc();
  const startOfThisMonth = dateCreator.startOfThisMonth.utc();
  const startOfLastMonth = dateCreator.startOfLastMonth.utc();
  const endOfLastMonth = dateCreator.endOfLastMonth.utc();

  switch (period) {
    case Period.TODAY:
      return { FromDate: todayStart.toISOString(), ToDate: todayEnd.toISOString() };
    case Period.YESTERDAY:
      return { FromDate: yesterdayStart.toISOString(), ToDate: yesterdayEnd.toISOString() };
    case Period.THIS_WEEK:
      return { FromDate: startOfThisWeek.toISOString(), ToDate: endOfThisWeek.toISOString() };
    case Period.LAST_WEEK:
      return { FromDate: startOfLastWeek.toISOString(), ToDate: endOfLastWeek.toISOString() };
    case Period.THIS_MONTH:
      return { FromDate: startOfThisMonth.toISOString(), ToDate: todayEnd.toISOString() };
    case Period.LAST_MONTH:
      return { FromDate: startOfLastMonth.toISOString(), ToDate: endOfLastMonth.toISOString() };
    default:
      return { FromDate: todayStart.toISOString(), ToDate: todayEnd.toISOString() };
  }
};

export const isPeriodDisabled = (period: string, date?: string | Date | null) => {
  const { ToDate } = getDateFromActiveDate(period);

  if (date) {
    return !(date < ToDate);
  }
  return false;
};

export const checkRegistrationDate = (registrationDateStr?: string | null) => {
  if (!registrationDateStr) return;

  const registrationDate = new Date(registrationDateStr);
  const today = new Date();

  const differenceInMilliseconds = today.getTime() - registrationDate.getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

  if (differenceInDays >= 365) {
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getUTCFullYear() - 1);

    return oneYearAgo.toISOString();
  } else {
    return registrationDate.toISOString();
  }
};
