/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export const ENDPOINTS = Object.freeze({
  // USER REGISTRATION
  registration: 'UserRegistration/Registration',
  editUserMail: 'UserRegistration/EditUserMail',
  sendRegistrationMail: 'UserRegistration/RegistrationMail',
  // PERSONAL DETAILS
  userDetails: 'PersonalDetails/UserDetails',
  saveUserDetails: 'PersonalDetails/SaveUserDetails',
  userTiles: 'PersonalDetails/UserTiles',
  // PREFERENCES
  setOptInDecision: 'Preferences/SetOptInDecision',
  setUserPreferences: 'Preferences/UserPreferences',
  setMarketingPreferences: 'Preferences/MarketingPreferences',
  // VEHICLE
  getVehicleManufacturers: 'Vehicle/GetVehicleManufacturers',
  getAllVehicleData: 'Vehicle/GetAllVehicleData',
  getUserVehicles: 'Vehicle/UserVehicles',
  userVehicle: 'Vehicle/UserVehicle',
  // ENERGY SETUP // LOCATION
  getEnergySetups: 'EnergySetup/GetEnergySetups',
  getEnergyProviders: 'EnergySetup/GetEnergyProviders',
  getBestPriceIntervalsGraph: 'EnergySetup/GetBestPriceIntervalsGraph',
  getDualTariffEnergyPrices: 'EnergySetup/GetDualTariffEnergyPrices',
  addNewEnergySetup: 'EnergySetup/AddNewEnergySetup',
  saveDualTariffEnergyPrices: 'EnergySetup/SaveDualTariffEnergyPrices',
  deleteEnergySetup: 'EnergySetup/DeleteEnergySetup',
  userLocation: 'Location',
  getLocations: 'Location',
  // PRODUCTS
  addNewHub: 'HubRegistration/AddNewHub',
  addNewDevice: 'HubRegistration/AddNewDevice',
  editHub: 'HubRegistration/EditHub',
  editDevice: 'HubRegistration/EditDevice',
  updateDevicesNickname: 'HubRegistration/UpdateDevicesNickname',
  updateLibbiComponentsNickname: 'HubRegistration/UpdateLibbiComponentsNickname',
  getUserHubsAndDevices: 'Product/UserHubsAndDevices',
  getDeletedHubsAndDevices: 'Product/DeletedHubsAndDevices',
  updateDevices: 'Product/UpdateDevices',
  deviceStatus: 'Product/DeviceStatus',
  getTechnicalFileData: 'Rig/TechnicalFileData',
  getOcppSettings: 'ocppConfig/GetOcppConfig',
  setOcppSettings: 'ocppConfig/SetOcppConfig',
  getOcppTransactionStatus: 'ocppConfig/getOcppTransactionStatus',
  sendOcppStopTransaction: 'ocppConfig/sendOcppStopTransaction',
  generateNewOcppTag: 'ocppConfig/GenerateOcppTag',
  // ACCOUNT SETTINGS
  checkAPIKey: 'AccountSettings/CheckAPIKey',
  generateAPIKey: 'AccountSettings/GenerateAPIKey',
  deleteDevice: 'AccountSettings/DeleteDevice',
  deleteHub: 'AccountSettings/DeleteHub',
  deleteAccount: 'AccountSettings/DeleteAccount',
  forecast: 'Weather/Forecast',
  // ACCOUNT SHARING
  getInvitations: 'Sharing/GetInvitations',
  setSelectedAccount: 'Sharing/SetSelectedAccount',
  sendInvitation: 'Sharing/SendInvitation',
  resendInvitationEmail: 'Sharing/ResendInvitationEmail',
  acceptInvitation: 'Sharing/AcceptInvitation',
  rejectInvitation: 'Sharing/RejectInvitation',
  updateInvitation: 'Sharing/UpdateInvitation',
  deleteInvitation: 'Sharing/DeleteInvitation',
  deleteInvitationByGuest: 'Sharing/DeleteInvitationByGuest',
  // GRAPHS
  retrieveSpecificDataForTotalGraph: 'Graphs/RetrieveSpecificDataForTotalGraph',
  retrieveSpecificDataForDeviceGraph: 'Graphs/RetrieveSpecificDataForDeviceGraph',
  retrieveGraphData: 'Graphs/DevicesOfHubData',
  retrieveGraphDataHourly: 'Graphs/DevicesOfHubDataHourly',
  // CHARGE SCHEDULE
  getAllSchedules: 'ChargeSchedules/GetAllSchedules',
  saveSchedules: 'ChargeSchedules/SaveSchedules',
  setIsActiveSchedule: 'ChargeSchedules/SetIsActiveSchedule',
  displaySchedules: 'ChargeSchedules/DisplaySchedules',
  libbiMode: 'AccountAccess/LibbiMode',
  libbiSyncMode: 'AccountAccess/MasterLibbi',
  // PAYMENT
  billingDetails: 'Payment/BillingDetails',
  saveBillingDetails: 'Payment/SaveBillingDetails',
  createSession: 'Payment/CreateSession',
  updatePaymentDetails: 'Payment/UpdatePaymentDetails',
  updateWarrantyAndSendConfirmationMail: 'Payment/UpdateWarrantyAndSendConfirmationMail',
  refund: 'Payment/Refund',
  // CHARGE LOGS
  getChargingSessions: 'ChargeLogs/ChargingSessions',
  downloadChargingSessions: 'ChargeLogs/DownloadData',
  toggleBusinessUsage: 'ChargeLogs/ToggleBusinessUsage',

  // ACCESS GROUPS
  getAccessGroups: 'AccessGroups/AccessManagement/Info',
  postAccessGrouops: 'AccessGroups',
  addDeviceToAccessGroup: 'AccessGroups/AddDevice',
  deleteAccessGroup: 'AccessGroups',
  removeDeviceFromAccessGroup: 'AccessGroups/RemoveDevice',
  // PIN
  pinEndpoint: 'Pin',
  devicePinFriendlyNames: 'Pin/DevicePinsFriendlyNames',
  addPins: 'Pin/AddPins',
  validatePin: 'Pin/ValidatePin',

  //REPORTS
  generateCSVReport: 'CSVReport/GenerateReport'
});
