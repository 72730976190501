/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import Button from 'components/elements/button';
import { BUTTON_COLORS, BUTTON_TYPE } from 'components/elements/button/consts';
import FormSeparator from 'components/elements/formSeparator';
import Icon from 'components/elements/icon';
import FieldSwitch from 'components/elements/switch';
import { setIsAnalyticalCookiesAllowed } from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { Formik, Form } from 'formik';
import { FC } from 'react';
import { PRIVACY_POLICY_URL, generateLanguageLink } from 'utils/termsLink';

import { CC_GA, CC_NSC, CC_SC } from './consts';
import { setCookie, getCookie, deleteCookie, ICookieBarFormProps } from './helper';

const CookieBarForm: FC<ICookieBarFormProps> = ({ data, setActive, language }) => {
  const timer = 365;
  const dispatch = useAppDispatch();
  const { isAnalyticalCookiesAllowed } = useAppSelector((state) => state.site);

  const handleSubmit = (values: { analyticalCookiesFormValue: boolean }) => {
    const { analyticalCookiesFormValue } = values;
    setCookie(CC_GA, analyticalCookiesFormValue, timer);
    setCookie(CC_NSC, true, timer);
    setCookie(CC_SC, true, timer);
    setActive(false);
    dispatch(setIsAnalyticalCookiesAllowed(analyticalCookiesFormValue));

    // Remove existing GA Cookie if user preferences changed
    getCookie(CC_GA) && !analyticalCookiesFormValue && deleteCookie(CC_GA);
  };

  const handleReject = () => {
    setCookie(CC_NSC, true, timer);
    setCookie(CC_SC, false, timer);
    setActive(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ analyticalCookiesFormValue: isAnalyticalCookiesAllowed }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="cookiebar__form">
          <div className="cookiebar__form--close">
            <Icon name="close-green" onClick={handleSubmit} testId="cookiebar-form-submit" />
          </div>

          <div className="cookiebar__wrapper">
            <div className="cookiebar__container">
              <span className="cookiebar__container--title">{data.title}</span>
            </div>

            <div className="cookiebar__container">
              <span className="cookiebar__container--desc">{data.desc1}</span>
            </div>

            <div className="cookiebar__container">
              <span className="cookiebar__container--desc">{data.desc2}</span>
              <a
                className="cookiebar__container--link"
                href={generateLanguageLink(language ?? '', PRIVACY_POLICY_URL, ['nl-NL'], 'nl/fr/')}
                rel="noreferrer"
                target="_blank"
              >
                {data.privacyPolicy}
              </a>
            </div>

            <div className="cookiebar__container">
              <Button
                type={BUTTON_TYPE.SUBMIT}
                className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled} cookiebar__submit`}
                label={data.acceptButton}
                isDisabled={isSubmitting}
              />
              <Button
                className={BUTTON_COLORS.green}
                label={data.declineButton}
                onClick={handleReject}
                isDisabled={isSubmitting}
              />
            </div>
          </div>
          <FormSeparator />

          {/* NECESARY COOKIES */}
          <div className="cookiebar__wrapper">
            <div className="cookiebar__container">
              <span className="cookiebar__container--header">{data.necessaryTitle}</span>
            </div>

            <div className="cookiebar__container">
              <span className="cookiebar__container--desc">{data.necessaryDesc}</span>
            </div>
          </div>
          <FormSeparator />

          {/* ANALYTICAL COOKIES */}
          <div className="cookiebar__wrapper">
            <div className="cookiebar__container">
              <div className="cookiebar__container--header">
                <span>{data.analyticalTitle}</span>
                <FieldSwitch
                  name="analyticalCookiesFormValue"
                  id="analyticalCookiesFormValue"
                  color={'green'}
                />
              </div>
            </div>

            <div className="cookiebar__container">
              <span className="cookiebar__container--desc">{data.analyticalDesc}</span>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CookieBarForm;
