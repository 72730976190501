/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { DeviceType } from 'constants/DeviceType';
import { InvitationStatus } from 'constants/enums';
import { dateFormated, STATUS, FL } from 'shared/js';
import { Device, SetDevicesAndHubListPayload } from 'types';
import { EmptyDevice } from 'types';
import dayjs from 'utils/dayjs';

import { Devices, Hubs, IDeviceStatuses, ProcessDeviceListParams } from './processors.types';

export const processDeviceList = ({ hubs, devices, offlineDevices }: ProcessDeviceListParams) => {
  const emptyDevice: EmptyDevice = {
    deviceName: null,
    deviceType: DeviceType.Unknown,
    extendedWarrantyExpirationDate: '',
    extendedWarrantyPrice: '',
    firmware: '',
    heaterOutput1: null,
    heaterOutput2: null,
    id: '',
    isCoolingOffAvailable: false,
    isDeleted: false,
    isEligibleForEW: false,
    isExtendedWarranty: false,
    refundsAvailable: 0,
    registrationStartDate: '',
    serialNumber: '',
    standardWarrantyExpirationDate: '',
    status: 1,
    timeZoneRegion: '',
    isVHub: false,
    locationId: '',
    g100LockoutState: 0,
    masterLibbiId: '',
    hasPins: false
  };
  const processedUserHubsAndDevices: SetDevicesAndHubListPayload = {
    hubList: [],
    devicesList: [],
    offlineProducts: [],
    deviceHeaters: {}
  };

  devices?.forEach((device: Devices) => {
    processedUserHubsAndDevices.devicesList.push({
      ...device.device,
      isVHub: device.isVHub,
      locationId: device.locationId,
      hasPins: device.hasPins
    });
    if (device.device.id ?? false) {
      processedUserHubsAndDevices.deviceHeaters[device.device.id] = {
        heaterOutput1: device.device.heaterOutput1,
        heaterOutput2: device.device.heaterOutput2
      };
    }
  });

  hubs?.forEach((hub: Hubs) => {
    const getDevicesFromHub = hub.deviceIds.map((deviceIdFromHub: string) => {
      const device = devices?.find((device: Devices) => device.device.id === deviceIdFromHub);

      return device
        ? {
            ...device?.device,
            isVHub: device?.isVHub,
            locationId: device?.locationId,
            hasPins: device.hasPins
          }
        : emptyDevice;
    });

    processedUserHubsAndDevices.hubList.push({
      ...hub.hub,
      devices: getDevicesFromHub,
      locationId: hub.locationId,
      hasPins: hub.hasPins
    });
  });

  offlineDevices?.forEach((offlineDevice) => {
    processedUserHubsAndDevices.offlineProducts.push({
      ...offlineDevice.device,
      isVHub: offlineDevice.isVHub,
      locationId: offlineDevice.locationId,
      hasPins: offlineDevice.hasPins
    });
  });

  return processedUserHubsAndDevices;
};

export const processSerialNO = (serialNo: string, firmware: string | null): string => {
  if (!firmware || firmware === null) {
    return `SN ${serialNo}`;
  }
  return `SN ${serialNo} · v${firmware}`;
};

export const processDate = (date: string | null, format: null | string | undefined) => {
  if (!date) return;
  const time = `${date}`.substring(0, 10);
  return format ? dayjs(time).format(dateFormated(format, FL.APP)) : time;
};

export const procesStatus = (
  data: IDeviceStatuses,
  status?: number | string
): { status: string; label: string } => {
  switch (status) {
    case 1:
      return { status: STATUS.new, label: data?.new };
    case 2:
      return { status: STATUS.active, label: data?.active };
    case 3:
      return { status: STATUS.inactive, label: data?.inactive };
    case 4:
      return { status: STATUS.new, label: data?.new };
    case 5:
      return { status: STATUS.deleted, label: data?.deleted };
    case InvitationStatus.ACCEPTED: {
      return { status: STATUS.accepted, label: data?.accepted };
    }
    case InvitationStatus.PENDING: {
      return { status: STATUS.pending, label: data?.pending };
    }

    default:
      return { status: STATUS.none, label: data?.none };
  }
};

export const processArrayList = (deviceList?: Device[], deviceId?: string | null) => {
  const device = deviceList?.find((x) => x.id === deviceId);
  return device;
};
