/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { CurrencyToParts, TilesImage } from './types';

// COGNITO STATES for REGSTEP
export const R_REGISTERED = 'unregistered';
export const R_NOTLOCALLY_REGISTERED = 'notLocal';
export const R_FULLY_REGISTERED = 'registered';

// APP STATES
export const SIGNED_IN = 'signedIn';
export const NORMAL_REGISTRATION = 'normalRegistration';
export const SOCIAL_SIGNED_IN = 'socialSignedIn';
export const SIGNED_OUT = 'signedOut';
export const VERIFICATION = 'registered';

// ERROR HANDLING
export const FORGOT_PASSWORD_INVALID_CODE = 'Invalid verification code provided, please try again.';
export const FORGOT_PASSWORD_INVALID_EMAIL =
  'Cannot reset password for the user as there is no registered/verified email or phone_number';
export const CHANGE_PASSWORD_INVALID_EMAIL = 'Incorrect username or password.';
export const LIMIT_EXCEEDED = 'Attempt limit exceeded, please try after some time.';
export const USER_NOT_FOUND = 'Username/client id combination not found.';

// USER ATTRIBUTES
export const USER_ATTRIBUTES = {
  displayNotifSet: 'custom:displayNotifSet',
  registrationStep: 'custom:registrationStep',
  marketingOptIn: 'custom:marketingOptIn',
  thirdPartyMarketingOptIn: 'custom:thirdPartyOptIn',
  privacyAcceptance: 'custom:privacyAcceptance'
};

//CRYPTO
export const S = 'myenergi';

//IMAGES
export const DEVICE_IMAGES: { [key: string]: string } = {
  hub: '/img/hub.png',
  zappi: '/img/zappi.png',
  rapidzappi: '/img/rapidzappi.png',
  eddi: '/img/eddi.png',
  harvi: '/img/harvi.png',
  libbi: '/img/libbi.png',
  eddiplus: '/img/eddiplus.png'
};

export const CAR_IMAGE = {
  tesla: '/img/tesla.png'
};

export const TILES_IMAGE: TilesImage = {
  account: 'img/tiles/location.png',
  location: 'img/tiles/location.png',
  locationSettings: 'img/tiles/location.png',
  shareControl: '/img/tiles/shareControl.png',
  addElectricVehicles: 'img/tiles/addElectricVehicles.png',
  addMyEnergyProducts: 'img/tiles/addMyEnergyProducts.png',
  yourRoutine: 'img/tiles/yourRoutine.png'
};
export const WARRANTY_DEVICES =
  process.env.REACT_APP_RAPID_ZAPPI === 'true'
    ? 'img/extended-warranty-devices-rzappi.png'
    : 'img/extended-warranty-devices.png';
export const WARRANTY_LOGO = 'img/warranty_logo.png';
export const HUB_INSTRUCTONS = 'img/hub1x.png';

//NAMES
export const WELCOME_MODAL = 'WELCOME_MODAL';
export const GENERIC_ROOT_POPUP = 'GENERIC_ROOT_POPUP';
export const FIRST_TIME_REG = 'FIRST_TIME_REG';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const PERSONAL_INFO = 'PERSONAL_INFO';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const PREFERENCE = 'PREFERENCE';
export const MARKETING = 'MARKETING';
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const LOCATION_SWITCH = 'LOCATION_SWITCH';
export const LOCATION_SETTINGS = 'LOCATION_SETTINGS';
export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const ADD_EDIT_USER = 'ADD_EDIT_USER';
export const REMOVE_SHARING = 'REMOVE_SHARING';
export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const CANNOT_REMOVE_ACTIVE_PRODUCT = 'CANNOT_REMOVE_ACTIVE_PRODUCT';
export const REMOVE_HUB = 'REMOVE_HUB';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ZAPPI_DUPLICATE_ERROR = 'ZAPPI_DUPLICATE_ERROR';
export const REGISTER_NEW_HUB = 'REGISTER_NEW_HUB';
export const REGISTER_EXISTING_HUB = 'REGISTER_EXISTING_HUB';
export const REGISTER_PRODUCT = 'REGISTER_PRODUCT';
export const SET_UP = 'SET_UP';
export const SCHEDULED_CHARGE = 'SCHEDULED_CHARGE';
export const SINGLE_CHARGE = 'SINGLE_CHARGE';
export const BUDGET_CHARGE = 'BUDGET_CHARGE';
export const SCHEDULED_BOOST = 'SCHEDULED_BOOST';
export const BUDGET_BOOST = 'BUDGET_BOOST';
export const LIBBI_BUDGET_CHARGE = 'LIBBI_BUDGET_CHARGE';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const NEW_TARIFF_SCHEME = 'NEW_TARIFF_SCHEME';
export const REMOVE_PRICE_PERIOD = 'REMOVE_PRICE_PERIOD';
export const SET_PRICE_PERIOD_PRICE = 'SET_PRICE_PERIOD_PRICE';
export const CHANGE_NICKNAME = 'CHANGE_NICKNAME';
export const INFO_MESSAGE = 'INFO_MESSAGE';
export const BILLING_DETAILS = 'BILLING_DETAILS';
export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const FLEX_BETA = 'FLEX_BETA';
export const CUSTOM_DATE = 'CUSTOM_DATE';
export const REMOVE_ACCESS_GROUP_DEVICE = 'REMOVE_ACCESS_GROUP_DEVICE';
export const ADD_PIN_CODE = 'ADD_PIN_CODE';
export const ADD_PIN_CODES = 'ADD_PIN_CODES';
export const EDIT_PIN_CODE = 'EDIT_PIN_CODE';
export const REMOVE_PIN = 'REMOVE_PIN';
export const ADD_ADDITIONAL_GROUP = 'ADD_ADDITONAL_GROUP';
export const REMOVE_ACCESS_GROUP = 'REMOVE_ACCESS_GROUP';
export const ADD_ACCESS_GROUP_DEVICE = 'ADD_ACCESS_GROUP_DEVICE';
export const OCPP_SETTINGS = 'OCPP_SETTINGS';
export const CHARGE_LOGS_FILTERS = 'CHARGE_LOGS_FILTERS';
export const RECONNECT_DEVICE = 'RECONNECT_DEVICE';
export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const EXTENDED_WARRANTY_WARNING = 'EXTENDED_WARRANTY_WARNING';
export const LIBBI_TARIFF_SETUP_WARNING = 'LIBBI_TARIFF_SETUP_WARNING';
export const WARNING_POPUP = 'WARNING_POPUP';
export const LIBBI_SYNC_SELECT = 'LIBBI_SYNC_SELECT';
export const LIBBI_SYNC_CANCEL = 'LIBBI_SYNC_CANCEL';
export const ACTIVE_FILTERS = 'ACTIVE_FILTERS';
export const CREATE_API_KEY = 'CREATE_API_KEY';
export const EDIT_API_KEY = 'EDIT_API_KEY';
export const REVOKE_API_KEY = 'REVOKE_API_KEY';
export const CSV_REPORTS_INFO_POPUP = 'CSV_REPORTS_INFO_POPUP';
export const EW_TILE = 'EW_TILE';
export const EXPORT_DATA_TILE = 'EXPORT_DATA_TILE';
export const AUTH_PIN = 'PIN';
export const AUTH_RFID = 'RFID';

//SOCIAL NAMES
export const GOOGLE = 'Google';
export const FACEBOOK = 'Facebook';
export const APPLE = 'SignInWithApple';

//TARIFF
export const OTHER_ENERGY_PROVIDER = 'Other';
export const ENTSOE_ENERGY_PROVIDER = 'ENTSOE';

export const CURRENCY_TO_PARTS: CurrencyToParts = {
  kr: {
    part: 'öre',
    partValue: 100
  }
};

//VALIDATION RULES
export const VALIDATION_RULES = {
  CODE_NINE: RegExp('^[\\w]{5}[-][\\w]{4}$'),
  NUMBER: RegExp('^[0-9]*?$'),
  EXACTLY: RegExp('^[0-9]{8}$'),
  EXACTLY_SIX: RegExp('^[0-9]{6}$'),
  CAPITAL: RegExp('^(?=.*[A-Z])[\\S\\d]{10,}$'),
  LOWERCASE: RegExp('^(?=.*[a-z])[\\S\\d]{10,}$'),
  NUMBER_INCLUDE: RegExp('^(?=.*\\d)[\\S\\d]{10,}$'),
  NUMBER_TEXT: 'Please only enter digits, do not include any letters.',
  CHECKSUM: 'checkSum',
  LIBBI_SERIAL_START: 'libbiSerialStart',
  EDDIPLUS_SERIAL_START: 'eddiPlusSerialStart',
  RAPIDZAPPI_SERIAL_START: 'rapidZappiSerialStart',
  SERIAL_MIN_LENGTH: 'serialMinRange',
  NUMBER_ONE_FOUR: RegExp('[1234]+$'),
  // FIX: this regex is not doing the same as the string it was converted from
  NO_SPECIAL_CHAR: RegExp('^[^-_?`~!@#$%^&*()=+,;[\\]].*[^-_?`~!@#$%^&*()=+,;[\\]]$'),
  LETTERS_AND_NUMBERS: RegExp('^[^-_?`~!@#$%^&*()=+,;[\\]]*$')
};

//WEATHER ICONS
export const WEATHER_ICONS = {
  thunderstorm: [200, 201, 202, 230, 231, 232, 233],
  drizzle: [300, 301, 302],
  rain: [500, 501, 502, 511, 520, 521, 522, 900],
  snow: [600, 601, 602, 610, 611, 612, 621, 622, 623],
  fog: [700, 711, 721, 731, 741, 751],
  cloudy: [801, 802, 803, 804]
};

export const SUN_ICON = 'sun';
export const MOON_ICON = 'moon';
export const CLEAR_SKY_CODE = 800;
export const WEATHER_ICON_PREFIX = 'weather';
export const MOON_ICON_DISPLAY_HOURS = { min: 18, max: 6 }; //moon icon should be displayed between 18 -> 06

//DATES
export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const TOMORROW = 'tomorrow';
export const WEEK = 'week';
export const MONTH = 'month';
export const DAY_RANGE = 24 * 60 * 60 * 1000;

//DEFAULT TIMEZONE
export const DEFAULT_TIMEZONE = 'Europe/London';

//PRODUCTS TABS
export const PRODUCTS_TABS = {
  live: 'live',
  deleted: 'deleted'
};

// LOCATION
export const EMPTY_LOCATION = {
  address: {
    id: '',
    siteName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    countryCode: '',
    isContactAddress: false,
    isEWEligible: false,
    isGuestAddress: false,
    currency: '',
    shouldValidateAddress: false
  },
  energySetup: {
    id: '',
    energyProvider: '',
    energyTariff: '',
    isEconomy: false,
    isEV: false,
    isFlexible: false,
    isGreen: false,
    isActive: false,
    isEntsoe: false,
    tariffExpirationDate: '',
    solarPanels: false,
    windTurbine: false,
    hydroelectric: false,
    otherGeneration: '',
    maxPowerOutput: 0,
    airSourceHeatPump: false,
    groundSourceHeatPump: false,
    electricStorageHeating: false,
    hasBattery: false,
    otherElectricHeating: '',
    currency: '',
    energyProviderId: ''
  },
  invitationData: null,
  isGuestLocation: false,
  ownerEmail: ''
};
