/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export enum HeaterType {
  heater1,
  heater2,
  relay1,
  relay2
}

export enum ZappiMode {
  noChange,
  fast,
  eco,
  ecoPlus,
  stop
}

export enum ZappiBoostMode {
  noChange,
  UNSUPPORTED_cancelNonTimed,
  cancelAll,
  startManual,
  startSmart
}

export enum TemperatureSensorType {
  tp1,
  tp2,
  pt1,
  pt2
}

export enum Period {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM'
}

export enum FilterType {
  TIME = 'TIME',
  USAGE_TYPE = 'USAGE_TYPE',
  FRIENDLY_NAMES = 'FRIENDLY_NAMES',
  CLEAR_ALL = 'CLEAR_ALL',
  CLEAR_SELECTED_FILTER = 'CLEAR_SELECTED_FILTER',
  AUTHORISATION = 'AUTHORISATION'
}

export enum UsageType {
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

export enum AuthorisationTypes {
  NONE = 'none',
  PIN = 'pin',
  RFID = 'rfid',
  OCPP = 'ocpp'
}

export enum Months {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December'
}

export enum G100LockedState {
  none = 0,
  local = 1,
  manufacturer = 2
}

export enum DefaultChargeMode {
  'None selected',
  'Fast',
  'Eco',
  'Eco+'
}

export enum AuthorisationType {
  NONE = 'None',
  PIN = 'Pin',
  RFID = 'RFID'
}

export enum AuthTypes {
  'None',
  'Pin',
  'OCPP',
  'RFID'
}

export enum InvitationStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}
