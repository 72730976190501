/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import cx from 'classnames';
import Button from 'components/elements/button';
import Icon from 'components/elements/icon';
import { useAppSelector } from 'customHooks';
import { Dayjs } from 'dayjs';
import chunk from 'lodash/chunk';
import range from 'lodash/range';
import { FC, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { buildYearsReversed } from 'shared/js';
import { checkRegistrationDate } from 'shared/js';
import dayjs from 'utils/dayjs';

import { BUTTON_COLORS, BUTTON_TYPE } from '../button/consts';
import SelectOption from '../select';
import { getMonthFrameList } from './consts';
import { IDay, CalendarProps, LabelValues, ICalendarData } from './datepicker.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Day: FC<IDay> = ({ i, w, d, active, disabledDay, startDate, className, data, ...props }) => {
  const prevMonth = w === 0 && i > 7;
  const nextMonth = w >= 4 && i <= 14;

  const cls = cx({
    'hidden-day': prevMonth || nextMonth,
    'current-day': !prevMonth && !nextMonth && active,
    'disabled-day': disabledDay,
    'selectable-day': !disabledDay && !(prevMonth || nextMonth)
  });

  return (
    <td
      className={cls}
      {...props}
      data-testid={!disabledDay && !(prevMonth || nextMonth) ? 'selectableDay' : 'unselectableDay'}
    >
      {!prevMonth && !nextMonth ? i : ''}
    </td>
  );
};

const Calendar: FC<CalendarProps> = (props) => {
  const {
    initialDate,
    onChange,
    onClose,
    startingRestrictedDate,
    endingRestrictedDate,
    validation = false,
    isEnergyUsageCalendar = false,
    calendarYearStart,
    calendarYearEnd,
    hubRegistrationDate,
    calendarName,
    isChargingSession = false,
    isCSVCalendar = false,
    timezone
  } = props;

  const [data, setData] = useState<ICalendarData>({} as ICalendarData);
  const startingDisabledDate = timezone
    ? dayjs(startingRestrictedDate).tz(timezone)
    : dayjs(startingRestrictedDate);

  const endingDisabledDate =
    timezone && calendarName !== 'fromCalendar' && !isCSVCalendar
      ? dayjs(endingRestrictedDate).tz(timezone)
      : dayjs(endingRestrictedDate);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [yearLabel, setYearLabel] = useState<Dayjs | string>('');
  const [monthLabel, setMonthLabel] = useState<Dayjs | string>('');
  const [toggleOk, setToggleOk] = useState<boolean>(true);
  const store = useAppSelector((state) => state);
  const language = store.userDetails.personalInfo.preferences.language;
  const locationId = store.location.activeLocation?.energySetup?.id;
  const calendarDateLimit =
    hubRegistrationDate && !isEnergyUsageCalendar
      ? hubRegistrationDate
      : dayjs(checkRegistrationDate(startingRestrictedDate || ''));

  const monthOptions = getMonthFrameList(data?.months);

  useEffect(() => {
    loadText('calendar', language).then((res) => {
      setData(res?.data);
    });
  }, [language]);

  useEffect(() => {
    if (initialDate) {
      setDate(dayjs(initialDate).clone());
      setSelectedDate(dayjs(initialDate).clone());
      setYearLabel(dayjs(initialDate).format('YYYY'));

      return;
    }

    setToggleOk(true);
    setSelectedDate(date.clone());
    setYearLabel(date.format('YYYY'));
    // eslint-disable-next-line
  }, [locationId]);

  useEffect(() => {
    yearLabel && setDate(date.set('year', Number(yearLabel)));
    // eslint-disable-next-line
  }, [yearLabel]);

  // Fix this lint error.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectDate = (i: number, w: number) => {
    const dayObj = dayjs()
      .utc()
      .set('year', date?.year())
      .set('month', date?.month())
      .set('date', i);
    const day = dayjs(dayObj).startOf('day');

    setSelectedDate(day);
    setToggleOk(true);
  };

  const handleChangeYearLabel = (yearObj: SingleValue<LabelValues>) => {
    if (yearObj) {
      setDate(date.set('year', Number(yearObj?.value)));
      setYearLabel(yearObj?.value);
      setToggleOk(false);
    }
  };

  const handleChangeMonthLabel = (monthObj: SingleValue<LabelValues>) => {
    if (monthObj && monthObj.key !== null && monthObj.key !== undefined) {
      setDate(date.set('month', monthObj.key));
      setMonthLabel(monthOptions[monthObj.key].label);
      setToggleOk(false);
    }
  };

  const handleSubmit = (date: Dayjs) => {
    if (typeof onChange === 'function' && date) {
      onChange(date);
    }

    handleClose();
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const checkIfActive = (day: number) =>
    selectedDate &&
    date.month() === selectedDate?.month() &&
    date.year() === selectedDate?.year() &&
    day === selectedDate?.date();

  const d = date.date();
  const d1 = date.clone().subtract(1, 'month').endOf('month').date();
  const d2 = date.clone().date(0).day();
  const d3 = date.clone().endOf('month').date();
  const days = [].concat(
    range(d1 - d2 + 1, d1 + 1) as [],
    range(1, d3 + 1) as [],
    range(1, 42 - d3 - d2 + 1) as []
  );
  const weeks = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  const checkIfDisabled = (d: []) => {
    const acceptedDateInterval = {
      start: dayjs(startingDisabledDate.format('YYYY-MM-DD')),
      end: dayjs(endingDisabledDate.format('YYYY-MM-DD'))
    };

    const month = date.month() + 1;
    const monthFormatted = month.toString().padStart(2, '0');
    const dayFormatted = d.toString().padStart(2, '0');

    const pickedDate = dayjs(`${yearLabel}-${monthFormatted}-${dayFormatted}`, 'YYYY-MM-DD');

    const isBetweenInterval = pickedDate.isBetween(
      acceptedDateInterval.start,
      acceptedDateInterval.end,
      'day',
      '[]'
    );

    return !isBetweenInterval;
  };

  const handleClickRegistrationDate = () => {
    const [year, month, day] = calendarDateLimit.format('YYYY-MM-DD').split('-');
    const dayObj = dayjs()
      .utc()
      .set('year', Number(year))
      .set('month', Number(month) - 1)
      .set('date', Number(day));

    const daySelected = dayjs(dayObj).startOf('day');

    handleSubmit(daySelected);
  };

  return (
    <div className="calendar neonglow__green" data-testid="calendar">
      <div className="calendar__top">
        <div className="calendar__title">{data?.calendarTitle}</div>
        <div className="popup__button">
          <Icon name="close-grey" onClick={handleClose} />
        </div>
      </div>

      <div className="calendar__selectors">
        <SelectOption
          className="calendar__select"
          optionList={buildYearsReversed(calendarYearStart, calendarYearEnd)}
          label={data?.selectorLabels?.year}
          name={`${calendarName}yearframe`}
          id={`${calendarName}yearframe`}
          onChange={handleChangeYearLabel}
          value={!!yearLabel ? (yearLabel as string) : dayjs(initialDate).format('YYYY')}
          defaultValue={
            {
              label: dayjs(initialDate).format('YYYY'),
              value: dayjs(initialDate).format('YYYY')
            } || {
              label: dayjs(date).format('YYYY'),
              value: dayjs(date).format('YYYY')
            }
          }
          hideSelectedOptions={false}
        />
        <SelectOption
          className="calendar__select"
          optionList={monthOptions}
          label={data?.selectorLabels?.month}
          name={`${calendarName}monthframe`}
          id={`${calendarName}monthframe`}
          onChange={handleChangeMonthLabel}
          value={!!monthLabel ? (monthLabel as string) : dayjs(initialDate).format('MMMM')}
          defaultValue={
            !!monthLabel
              ? {
                  label: monthLabel as string,
                  value: dayjs(date).format('MMMM')
                }
              : {
                  label: dayjs(initialDate).locale(language).format('MMMM'),
                  value: dayjs(initialDate).format('MMMM')
                }
          }
          hideSelectedOptions={false}
        />
      </div>

      <table unselectable="on">
        <thead>
          <tr>
            {weeks.map((w, i) => (
              <td key={i}>{w}</td>
            ))}
          </tr>
        </thead>

        <tbody>
          {chunk(days, 7).map((row, w) =>
            w === 5 && row[0] < 8 ? null : (
              <tr key={w}>
                {row.map((i) => {
                  // eslint-disable-next-line no-lone-blocks
                  return (
                    <Day
                      className=""
                      key={i}
                      i={i}
                      d={d}
                      w={w}
                      disabledDay={validation && checkIfDisabled(i)}
                      startDate={props?.startDate?.date()}
                      active={checkIfActive(i)}
                      onClick={() => selectDate(i, w)}
                    />
                  );
                })}
              </tr>
            )
          )}
        </tbody>
      </table>
      {isEnergyUsageCalendar && (
        <div className="calendar__info">
          {data?.calendarInfo}{' '}
          <span onClick={() => handleClickRegistrationDate()}>
            {hubRegistrationDate?.format('DD/MM/YYYY')}
          </span>
          {!isChargingSession && !isCSVCalendar && <div>{data?.calendarInfoLimitation}</div>}
        </div>
      )}

      <div className="calendar__buttons">
        <Button
          className={BUTTON_COLORS.green}
          type={BUTTON_TYPE.SUBMIT}
          onClick={handleClose}
          label={data?.calendarCancel}
          testId="calendar-cancel"
        />
        <Button
          className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
          type={BUTTON_TYPE.SUBMIT}
          onClick={() => handleSubmit(selectedDate)}
          label={data?.calendarSubmit}
          isDisabled={!toggleOk}
          testId="calendar-submit"
        />
      </div>
    </div>
  );
};

export default Calendar;
