/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import ExpandableMenuButton from 'components/elements/expandableMenuButton';
import MenuButton from 'components/elements/menuButton';
import { DeviceType, DevicesType } from 'constants/DeviceType';
import { G100LockedState } from 'constants/enums';
import { route } from 'containers/appRouter';
import { toggleMobileDrawer } from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SIGNED_IN } from 'shared/js';
import { getAMAvailableDevices, getDevicesFromLocation } from 'shared/js';
import { Device } from 'types';

import Logo from '../logo';
import { MENU_INITIAL_DATA } from './menu.consts';
import { IMenuData } from './menu.types';
import { getSubmenuValues } from './submenu-Tabs';

const publicApiTabVisible = process.env.REACT_APP_API_KEYS_VISIBLE === 'true';

const Menu = ({ testId = 'menu-id' }) => {
  const hash = useLocation().hash;
  const pathname = useLocation().pathname;
  const [data, setData] = useState<IMenuData | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | boolean>(pathname);
  const store = useAppSelector((store) => store);
  const dispatch = useAppDispatch();

  // Tabs
  const allSubmenuTabs = data ? getSubmenuValues(data?.submenuTabs) : MENU_INITIAL_DATA.submenuTabs;
  const accountTabs = [...Object.values(allSubmenuTabs?.account)];
  const locationTabs = [...Object.values(allSubmenuTabs?.location)].filter(
    (tab) => tab?.hash !== '#sharing'
  );
  const energyUsageTabs = [...Object.values(allSubmenuTabs?.energyUsage)].filter(
    (tab) => tab?.hash !== '#sessions'
  );
  const energyUsageTabsCharging = [...Object.values(allSubmenuTabs?.energyUsage)];

  // Store
  const { location, products, site, userDetails } = store;
  const { activeLocation } = location;
  const { activeHub, hubList, devicesList } = products;
  const { mobileDrawer, isMenuVisible } = site;
  const { isGuestLocation } = activeLocation;
  const { language } = userDetails?.personalInfo.preferences;
  const { authState } = userDetails;
  const activeLocationId = activeLocation?.energySetup?.id;
  const administratorAccess = activeLocation.invitationData?.administratorAccess;

  const isActiveLocation = !isEmpty(activeLocation?.address?.id);
  const hubsFromAddress = hubList?.filter(
    (hub) => hub.locationId === activeLocation?.energySetup?.id
  );
  const devicesFromAddress = devicesList?.filter(
    (device) => device.locationId === activeLocation?.energySetup?.id
  );
  const G100locedDevicesFromAddress = devicesFromAddress?.filter(
    (device) => device.g100LockoutState === G100LockedState.manufacturer
  );
  const onlineProductsOnLocation = activeHub?.devices;

  const restrictAccessManagement =
    (isGuestLocation && !administratorAccess) ||
    isEmpty(getAMAvailableDevices(activeLocationId, devicesList)) ||
    !isActiveLocation;
  const locationTabsFiltered =
    isEmpty(activeHub) || restrictAccessManagement
      ? locationTabs.filter((tab) => tab.label !== data?.submenuTabs.location.accessManagement)
      : locationTabs;

  const config =
    isGuestLocation || isEmpty(activeHub) || isEmpty(onlineProductsOnLocation) || !isActiveLocation
      ? locationTabsFiltered
      : locationTabsFiltered.concat([allSubmenuTabs.location.sharing]);

  const accountTabsFiltered = publicApiTabVisible
    ? accountTabs
    : accountTabs.filter((tab) => tab.label !== data?.submenuTabs.account.publicApi);

  // Conditions
  const isActiveHub = !isEmpty(activeHub?.id);
  const isInvitationData = activeLocation?.invitationData?.administratorAccess;
  const isAppAccess = activeLocation?.invitationData?.appAccess;
  const { isEconomy, isFlexible } = activeLocation?.energySetup;
  const isEligibleTariff = isEconomy || isFlexible;
  const allDevFromLocation = getDevicesFromLocation(
    activeLocation?.energySetup?.id,
    store?.products?.hubList
  );
  const hideMenuOption =
    hubsFromAddress?.length === 1 && isEmpty(onlineProductsOnLocation) && !activeHub?.isVHub;

  // Conditions for showing Schedules button
  const devicesSupportSchedules = devicesFromAddress?.filter(
    (device) =>
      device.deviceType === String(DevicesType.zappi) ||
      device.deviceType === String(DevicesType.eddi) ||
      device.deviceType === String(DevicesType.eddiplus) ||
      device.deviceType === String(DevicesType.libbi)
  );
  const showSchedulesMenu =
    isActiveHub &&
    isEligibleTariff &&
    !hideMenuOption &&
    !isEmpty(devicesSupportSchedules) &&
    isEmpty(G100locedDevicesFromAddress);
  const showSchedulesMenuGuestLocation =
    isAppAccess &&
    isEligibleTariff &&
    !hideMenuOption &&
    !isEmpty(devicesSupportSchedules) &&
    isEmpty(G100locedDevicesFromAddress);

  // Conditions for showing Tariff buttons
  const showTariffButtons = isActiveHub && isEligibleTariff && isEmpty(G100locedDevicesFromAddress);
  const showTariffButtonsGuestLocation =
    isInvitationData && isEligibleTariff && isEmpty(G100locedDevicesFromAddress);

  const today = new Date();
  const isZappi = (dev: Device) =>
    dev.deviceType === DeviceType.Zappi || dev.deviceType === DeviceType.RapidZappi;

  useEffect(() => {
    loadText('leftmenu', language).then((res) => setData(res?.data));
  }, [language]);

  const renderTariffButtons = (bool: boolean) => {
    return bool ? (
      <MenuButton
        title={data?.mytariff}
        icon="tariff"
        route={route.DUAL_TARIFF}
        path={pathname}
        testId={`${testId}-dual-tariff`}
        linkTestId={`${testId}-dual-tariff-link`}
      />
    ) : (
      <MenuButton
        title={data?.mytariff}
        icon="tariff"
        route={route.AGILE_TARIFF}
        path={pathname}
        testId={`${testId}-agile-tariff`}
        linkTestId={`${testId}-agile-tariff-link`}
      />
    );
  };

  const expandableMenuButtonClick = (route: string) => {
    setActiveMenu(route === activeMenu ? false : route);
  };

  if (isMenuVisible && authState === SIGNED_IN && data) {
    return (
      <div className={`menu ${mobileDrawer ? 'menu--visible' : ''}`} data-testid={testId}>
        <div className="menu__drawer">
          <div className="menu__content">
            <div className="menu__content--menulist">
              <div className="menu__content--logo">
                <Logo />
              </div>
              <MenuButton
                title={data?.dashboard}
                icon="dashboard"
                route={route.DASHBOARD}
                path={pathname}
                testId={`${testId}-dashboard`}
              />

              {!activeLocation?.isGuestLocation ? (
                <>
                  {showTariffButtons && renderTariffButtons(isEconomy)}
                  {showSchedulesMenu && (
                    <MenuButton
                      title={data?.myschedules}
                      icon="flextariff"
                      route={route.SCHEDULES}
                      path={pathname}
                      testId={`${testId}-schedules`}
                    />
                  )}
                  {isActiveHub && !hideMenuOption && (
                    <ExpandableMenuButton
                      active={activeMenu === route.ENERGY_USAGE}
                      onClick={expandableMenuButtonClick}
                      title={data?.graphs}
                      icon="energyusage"
                      route={route.ENERGY_USAGE}
                      tabs={
                        allDevFromLocation?.some(isZappi)
                          ? energyUsageTabsCharging
                          : energyUsageTabs
                      }
                      hash={hash}
                      testId={`${testId}-energy`}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* FOR GUEST USERS*/}
                  {showTariffButtonsGuestLocation && renderTariffButtons(isEconomy)}
                  {showSchedulesMenuGuestLocation && (
                    <MenuButton
                      title={data?.myschedules}
                      icon="flextariff"
                      route={route.SCHEDULES}
                      path={pathname}
                    />
                  )}
                  {isActiveHub && !hideMenuOption && (
                    <ExpandableMenuButton
                      active={activeMenu === route.ENERGY_USAGE}
                      onClick={expandableMenuButtonClick}
                      title={data?.graphs}
                      icon="energyusage"
                      route={route.ENERGY_USAGE}
                      tabs={
                        allDevFromLocation?.some(isZappi)
                          ? energyUsageTabsCharging
                          : energyUsageTabs
                      }
                      hash={hash}
                      testId={`${testId}-energy`}
                    />
                  )}
                </>
              )}
            </div>

            <div className="menu__content--bottomlist">
              {
                <ExpandableMenuButton
                  active={activeMenu === route.LOCATION}
                  onClick={expandableMenuButtonClick}
                  title={data?.location}
                  icon="location"
                  route={route.LOCATION}
                  tabs={config}
                  hash={hash}
                />
              }

              <ExpandableMenuButton
                active={activeMenu === route.ACCOUNT}
                onClick={expandableMenuButtonClick}
                title={data?.account}
                icon="account"
                route={route.ACCOUNT}
                tabs={accountTabsFiltered}
                hash={hash}
                testId={`${testId}-account`}
              />

              {isActiveHub && !hideMenuOption && (
                <MenuButton
                  title={data.dataReports}
                  icon="data-reports"
                  route={route.DATA_REPORTS}
                  path={pathname}
                  testId={`${testId}-data-reports`}
                />
              )}

              <MenuButton
                title={data?.logout}
                icon="logout"
                className="menu__logout"
                route={route.LOGOUT}
                path={pathname}
                type="row"
                testId={`${testId}-logout`}
              />

              <div className="menu__copyright">
                <span>{`${data?.copyrightText}-${today.getUTCFullYear()}`}</span>
              </div>
            </div>
          </div>
          <div
            className="menu__exit"
            onClick={() => dispatch(toggleMobileDrawer(false))}
            data-testid={`${testId}-close`}
          ></div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Menu;
