/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import germanStrings from 'react-timeago/lib/language-strings/de';
import englishStrings from 'react-timeago/lib/language-strings/en';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import dutchStrings from 'react-timeago/lib/language-strings/nl';
import { TODAY, YESTERDAY, WEEK, DAY_RANGE } from 'shared/js';
import dateCreator from 'utils/dateCreator';
import dayjs from 'utils/dayjs';

import { UNIT_KW } from './const';

// required by highcharts
window.dayjs = dayjs;

export const getStartingTimestampByLabel = (label) => {
  const d = new Date();
  d.setMilliseconds(0);
  d.setSeconds(0);

  if (label === 'minute') {
    return Number(d);
  }

  d.setUTCMinutes(0);

  if (label === 'hour') {
    return Number(d);
  }

  d.setUTCHours(0);

  if (label === 'yesterday') {
    // eslint-disable-next-line @myenergi-ltd/prefer-utc-date
    d.setDate(d.getDate() - 1);
  }

  if (label === 'week') {
    // eslint-disable-next-line @myenergi-ltd/prefer-utc-date
    d.setDate(d.getDate() - 7);
  }

  return Number(d);
};

export const getRangeInMs = (range = TODAY) => (range === YESTERDAY ? DAY_RANGE : null);

export const getStartDateRange = (range = TODAY) => {
  if (typeof range === 'number') {
    return range;
  }

  let dateTime = dateCreator.now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  if (range === YESTERDAY) {
    dateTime.subtract(1, 'day');
  } else if (range === WEEK) {
    dateTime.subtract(6, 'day');
  }

  return dateTime;
};

export const getEndDateRange = (range = TODAY) => {
  if (typeof range === 'number') {
    return range;
  }

  // no need for end date for today and week
  if (range === TODAY || range === WEEK) {
    return undefined;
  }

  // if range is yesterday return today's midnight
  const dateTime = dateCreator.now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return dateTime;
};

// this is being replaced by startDate and endDate from the graphs store
export const getSelectedPeriod = (range = TODAY) => {
  return [getStartDateRange(range), getEndDateRange(range)];
};

// returns number of hours or mins for given period depending on data resolution
export const chartAPIDuration = (startDate, endDate, highResolution) => {
  return highResolution
    ? dayjs(endDate)
        .endOf('day')
        .add(1, 'millisecond')
        .diff(dayjs(startDate).startOf('day'), 'minutes')
    : dayjs(endDate).add(1, 'millisecond').diff(startDate, 'hours');
};

export const formatTooltipTime = (timestamp) => {
  const time = dayjs(timestamp).tz('Etc/UTC', true);

  return time.format('D MMM, HH:mm');
};

export const buildChartOptions = (props) => {
  const {
    dataSets = [],
    unitMeasurement = UNIT_KW,
    minY = null,
    animation,
    chartHeight = 630,
    fontSize,
    arrows,
    hasPercentageAxis,
    textData
  } = props || {};

  function formatTooltipLabel() {
    const formatPoint = (acc, point) => {
      const { label, unit, color, name } = point.series.userOptions;
      const labelText = textData.series[name] || label;
      const value = Number(point.y).toFixed(2);
      const convertedUnit = `<strong style="color:${color};">${unit.toLowerCase() === 'kwh' ? 'kW' : unit}</strong>`;
      return `${acc ? `${acc}` : ''}<strong style="color:${color}; text-transform: capitalize;">${labelText}: ${value}</strong> ${convertedUnit}<br/>`;
    };

    return [
      '<strong style="color:#ffffff;">' + formatTooltipTime(this.x, true) + '</strong>'
    ].concat(this.points ? this.points.reduce(formatPoint, '') : []);
  }

  const percentageAxis = hasPercentageAxis
    ? [
        {
          labels: {
            y: 4,
            x: 10,
            align: 'left',
            formatter: function () {
              return this.value >= 0 ? `${this.value} %` : '';
            },
            style: {
              fontSize: fontSize || '14px'
            }
          },
          opposite: true,
          lineWidth: 1,
          //tickInterval: 10,
          lineColor: '#202020',
          gridLineColor: '#2f2f2f',
          gridLineDashStyle: 'dash',
          //tickAmount: 5,
          startOnTick: true,
          endOnTick: true
        }
      ]
    : [];

  return {
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },
    series: dataSets,
    //colors: dataSets.map((el) => el.color),
    time: {
      timezone: dateCreator.Timezone
    },
    tooltip: {
      formatter: formatTooltipLabel,
      backgroundColor: '#111214',
      borderColor: '#ffffff',
      borderRadius: 5,
      borderWidth: 1
      //shared: true,
      //split: false,
      // shared: true,
      // crosshairs: true
    },
    xAxis: {
      tickWidth: 0,
      lineColor: '#202020',
      dateTimeLabelFormats: {
        day: '%e %b',
        week: '%e %b'
      },
      labels: {
        y: 30,
        style: {
          color: '#707070',
          fontSize: fontSize || '14px'
        }
      }
    },
    yAxis: [
      {
        labels: {
          align: 'right',
          y: 4,
          x: -19,
          formatter: function () {
            return this.value ? this.value : `${this.value} ${unitMeasurement || 'kW'}`;
          },
          style: {
            color: '#707070',
            fontSize: fontSize || '14px'
          }
        },
        min: minY,
        lineColor: '#202020',
        lineWidth: 1,
        gridLineColor: '#2f2f2f',
        gridLineDashStyle: 'dash',
        //tickAmount: 5,
        tickInterval: 2,
        startOnTick: true,
        endOnTick: true,
        zoomEnabled: false,
        allowDecimals: true,
        opposite: false,
        reversedStacks: false
      },
      ...percentageAxis
    ],
    navigator: {
      series: { threshold: 0 },
      margin: 75,
      xAxis: {
        labels: {
          y: 20,
          style: {
            color: '#707070',
            fontSize: fontSize || '14px'
          }
        },
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b'
        },
        startOnTick: false,
        gridLineWidth: 0,
        gridLineColor: '#ff0000'
      },
      height: 100,
      outlineWidth: 1,
      outlineColor: '#FF008C',
      maskInside: true,
      maskFill: 'rgba(255, 255, 255, 0.2)',
      handles: {
        height: 24,
        width: 24,
        symbols: [`url(${arrows})`, `url(${arrows})`],
        enabled: true
      }
    },
    plotOptions: {
      series: {
        //showInNavigator: true,
        threshold: 0
      },
      areaspline: {
        fillOpacity: 1,
        lineWidth: 0
      }
    },
    chart: {
      alignThresholds: true,
      backgroundColor: 'transparent',
      type: 'areaspline',
      height: chartHeight,
      animation,
      width: null,
      spacingBottom: 40,
      zoomType: 'x'
    },
    accessibility: {
      enabled: false
    }
  };
};

export const getTimeAgoFormat = (language) => {
  switch (language) {
    case 'fr-FR':
      return buildFormatter(frenchStrings);
    case 'nl-NL':
      return buildFormatter(dutchStrings);
    case 'de':
      return buildFormatter(germanStrings);
    default:
      return buildFormatter(englishStrings);
  }
};
