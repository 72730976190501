/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { LANGUAGES } from 'shared/js';

export const WARRANTY_TERMS_AND_CONDITIONS_URL = 'terms-and-conditions/myenergi-product-warranty/';
export const PRIVACY_POLICY_URL = 'terms-and-conditions/privacy-policy/';
export const DOWNLOAD_CENTRE_URL = 'installers-centre/download-centre/';
export const TERMS_AND_CONDITIONS_URL = 'terms-and-conditions/';

export const generateLanguageLink = (
  language: string,
  path: string = '',
  useOtherLinkFor: string[] = [],
  alternativeLanguage: string = ''
): string => {
  const baseLink = 'https://www.myenergi.com/';
  const shouldUseOtherLink = useOtherLinkFor && useOtherLinkFor.includes(language);

  let languageUrl: string;

  switch (language) {
    case LANGUAGES.EN_GB:
      languageUrl = '';
      break;
    case LANGUAGES.DE:
      languageUrl = shouldUseOtherLink ? alternativeLanguage : 'de/';
      break;
    case LANGUAGES.FR:
      languageUrl = shouldUseOtherLink ? alternativeLanguage : 'nl/fr/';
      break;
    case LANGUAGES.NL:
      languageUrl = shouldUseOtherLink ? alternativeLanguage : 'nl/';
      break;
    default:
      languageUrl = '';
      break;
  }

  return baseLink + languageUrl + path;
};

export const getCustomerSuportURL = (language: string): string => {
  switch (language) {
    case LANGUAGES.EN_GB:
      return 'https://www.myenergi.com/customer-support/';
    case LANGUAGES.DE:
      return 'https://www.myenergi.com/de/hilfe-support/';
    case LANGUAGES.FR:
      return 'https://www.myenergi.com/nl/fr/customer-support/';
    case LANGUAGES.NL:
      return 'https://www.myenergi.com/nl/product-support/';
    default:
      return 'https://www.myenergi.com/customer-support/';
  }
};

export const getCyberSecurityURL = (language: string): string => {
  switch (language) {
    case LANGUAGES.EN_GB:
      return 'https://support.myenergi.com/hc/en-gb/articles/11387108470417-Cyber-Security-Guidance-Using-your-zappi-securely';
    case LANGUAGES.DE:
      return 'https://support.myenergi.com/hc/de-de';
    case LANGUAGES.FR:
      return 'https://support.myenergi.com/hc/fr-be';
    case LANGUAGES.NL:
      return 'https://support.myenergi.com/hc/nl-nl';
    default:
      return 'https://support.myenergi.com/hc/en-gb/articles/11387108470417-Cyber-Security-Guidance-Using-your-zappi-securely';
  }
};
